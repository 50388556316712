@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
	font-size: 100%;
}

@layer components {
	h1 {
		@apply p-0 mt-0 text-[40px] md:text-[45px] leading-normal font-bold font-Chronic;
		foo: bar;
	}
	h2 {
		@apply text-center block w-full text-[50px] md:text-[74px] text-blue font-normal font-Chronic leading-tight;
		foo: bar;
	}
	h3 {
		@apply text-center pt-0 mt-0 text-[24px] sm:text-[34px] font-normal font-Chronic leading-tight text-blue;
		foo: bar;
	}

	.btn {
		@apply inline-block border-0 font-Chronic  bg-red rounded-full text-white  text-[30px] leading-[50px]  uppercase pb-1 px-4 text-center cursor-pointer shadow-[0px_11px_17px_0px_rgba(0,0,0,0.25)] disabled:pointer-events-none disabled:opacity-50;
		foo: bar;
	}

	.container {
		@apply mx-auto w-full max-w-[944px];
		foo: bar;
	}

	.inputElem {
		@apply w-full rounded-full  pt-2 px-4 text-[18px]  text-blue bg-white h-[50px] leading-[50px] outline-0 border-[1px] border-blue;
	}
	.inputElemErr {
		@apply text-red bg-[#FFD5D5] border-[1px] border-red;
	}

	.selectElem {
		@apply appearance-none bg-[url(/src/images/selectanchor.png)] bg-[length:40px] bg-right bg-no-repeat;
	}

	.formErrorMsg {
		@apply text-red py-2 text-[14px];
		foo: bar;
	}

	.p-link .link,
	.p-link a {
		@apply text-red underline;
		foo: bar;
	}

	.pageContent h1 {
		@apply pb-8;
	}
	.pageContent h2 {
		@apply pb-6 hyphens-auto;
	}

	p {
		@apply text-blue;
	}
}

/* Slider */
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}
.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;
}
.slick-list:focus {
	outline: none;
}
.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.slick-track:before,
.slick-track:after {
	content: "";
	display: table;
}
.slick-track:after {
	clear: both;
}
.slick-loading .slick-track {
	visibility: hidden;
}
.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	display: none;
}
[dir="rtl"] .slick-slide {
	float: right;
}
.slick-slide img {
	display: block;
}
.slick-slide.slick-loading img {
	display: none;
}
.slick-slide.dragging img {
	pointer-events: none;
}
.slick-initialized .slick-slide {
	display: block;
}
.slick-loading .slick-slide {
	visibility: hidden;
}
.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
	display: none;
}
.slick-dots {
	text-align: center;
	padding-left: 0;
	line-height: 10px;
	margin-top: -16px;
	padding-bottom: 16px;
}
.slick-dots button {
	display: none;
}
.slick-dots li {
	display: inline-block;
	opacity: 0.2;
	width: 15px;
	height: 15px;
	border-radius: 5px;
	margin: 0 4px;
	opacity: 1;
	background-size: contain;
	background-image: url(./images/dot.png);
}
.slick-dots li.slick-active {
	background-image: url(./images/dot-active.png);
}
.slick-arrow {
	position: absolute;
	top: calc(50% - 32px);
	transform: translateX(-50%, -50%);
	z-index: 2;
	font-size: 0;
	height: 48px;
	width: 48px;
	background-size: auto 50%;
	border: none;
	outline: none;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}
.slick-arrow.slick-prev {
	left: 1.5rem;
	background-image: url(./images/arrowRedLeft.png);
	margin-top: -4rem;
}
.slick-arrow.slick-next {
	background-image: url(./images/arrowRedRight.png);
	right: 1.5rem;
	margin-top: -4rem;
}

.slick-arrow.slick-prev.slick-disabled,
.slick-arrow.slick-next.slick-disabled {
	opacity: 0;
}
.composition-category-slider .slick-track > * {
	margin: 0 4px;
}

/* Crisp image rendering */

.crisp-image {
	image-rendering: -moz-crisp-edges; /* Firefox */
	image-rendering: -o-crisp-edges; /* Opera */
	image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
	image-rendering: crisp-edges;
}

// padding for ferrero header
.headerPadding {
	padding-top: 56px;
	@media (max-width: 1023px) {
		padding-top: 50px;
	}
}

text {
	font-display: optional;
}

.ferrHeader {
	position: relative;
	z-index: 2;
}
.reactapp {
	position: relative;
	z-index: 1;
}

.accordionitem p.pb-6:last-of-type {
	padding-bottom: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}
